var apiPath = 'https://dct-logs-api.neversaynever.work'
// var apiPath = 'https://logs-api.neversaynever.work'
// var apiPath = 'http://localhost:9595'

async function getFolderName(){
    let folder_name = await fetch(`${apiPath}/logs-folder`)
    let folder_name_data = await folder_name.json()
    return folder_name_data
}

async function getLogsTail(filename,line_count){
    try{
        let log_tail = await fetch(`${apiPath}/logs-tail?filename=${filename}&line_count=${line_count}`)
        let log_tail_json = await log_tail.json()
        
        return log_tail_json
    }catch(e){
        throw e
    }
}

export {getFolderName,getLogsTail}