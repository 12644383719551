import logo from './logo.svg';
import {useEffect,useState} from 'react'
import {getFolderName,getLogsTail} from './apis'
import styled from 'styled-components'
 
function App() {

  const [folderNames,setFolderNames] = useState([])
  const [fileNameClick,setFilenameClick] = useState('')
  const [logTails,setLogTails] = useState([])
  const [line_count,setLine_count] = useState(1000)

  useEffect(()=>{
    async function getFolderNameFun(){
      let folderNamesApi = await getFolderName()
      setFolderNames(folderNamesApi.folder_name)
    }
    getFolderNameFun()
  },[])


  const readLog = async function(filename){
    console.log(filename)
    let line_count = 5000 // 預設顯示的倒數行數
    try{
    let getLogsTailApi = await getLogsTail(filename,line_count)
    
    let logs_tail_data = getLogsTailApi.logs_tail
    // console.log('logs_tail:',logs_tail_data)
    // console.log(typeof logs_tail_data)
    // logs_tail_data = logs_tail_data.spilt('brbr')

    console.log('logs_tail2:',logs_tail_data)

    // logs_tail = logs_tail !== undefined ? logs_tail.replace(/brbr/g, <br />) : "";

    // console.log('logs_tail_line',logs_tail_line)
      if(logs_tail_data){
        setFilenameClick(filename)
        setLine_count(line_count)
        setLogTails(logs_tail_data)
      }else{
        throw new Error('api讀取失敗')
      }
    }catch(e){
      console.log(e)
      alert(e.message)
    }
  }

  const reverseLogs = function(){
    let newLogs = [...logTails].reverse()
    setLogTails(newLogs)
  }

  const LogDiv = styled.div`
    overflow-y: scroll;
    height:80vh;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    text-align:left;
  `

  const Button = styled.button`
    background-color:white;
    border:1px solid black;
    border-radius:5px;
    padding:3px;
    margin:1px;
  `

  return (
    <div style={{margin:'10px'}}>
      <div style={{marginBottom:'10px'}}>Logs檔案</div>
      <div>{folderNames.length?folderNames.map((folderName,i)=>{
        return <Button onClick={()=>readLog(folderName)} key={i}>{folderName}</Button>
      }):''}</div>
      <div style={{marginTop:'30px',marginBottom:'10px'}}>
        Logs(倒數{line_count}行) - 共{logTails.length-1}行 [{fileNameClick}]
        <Button style={{color:'blue'}} onClick={()=>reverseLogs()}>倒轉</Button>
      </div>
      <LogDiv>
        {logTails.map(logTail=>{
          return<p style={{margin:'3px',color:'#536878'}}>{logTail}</p>
        })}
      </LogDiv>
    </div>
  );
}

export default App;
